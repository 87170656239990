import React from "react";
import { Route, Routes } from "react-router-dom";
import { InvitationRoutes } from "./InvitationRoutes";

const FeatureInvitation = {
  InvitationPage: React.lazy(
    () => import("pages/Invitations/views/InvitationPage")
  ),
};

export const InvitationAppRoutes = () => (
  <Routes>
    <Route key={"InvitationRoutes"}>
      <Route
        path={InvitationRoutes.toInvitation()}
        element={<FeatureInvitation.InvitationPage />}
      />
    </Route>
  </Routes>
);
