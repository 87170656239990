import {
  AccountAppRoutes,
  AccueilAppRoutes,
  BanqueAppRoutes,
  CommandeAppRoutes,
  CompteAppRoutes,
  ConfigAppRoutes,
  InterfaceGlobalAppRoutes,
  PaymentAppRoutes,
  RechargerAppRoutes,
  TransfertAppRoutes,
  UserAppRoutes,
  VehiculeAppRoutes,
  PrestationAppRoutes,
  EnvoyerAppRoutes,
  SiteAppRoutes,
} from "pages";
import { RetirerAppRoutes } from "pages/Banques/Retirer";
import { FactureAppRoutes } from "pages/Factures/FactureAppRoutes";
import { InvitationAppRoutes } from "pages/Invitations/InvitationAppRoutes";

export const BeleeAppRoutes = () => (
  <div>
    <AccountAppRoutes />
    <CompteAppRoutes />
    <UserAppRoutes />
    <SiteAppRoutes />
    <InterfaceGlobalAppRoutes />
    <VehiculeAppRoutes />
    <CommandeAppRoutes />
    <AccueilAppRoutes />
    <PaymentAppRoutes />
    <BanqueAppRoutes />
    <TransfertAppRoutes />
    <RechargerAppRoutes />
    <RetirerAppRoutes />
    <EnvoyerAppRoutes />
    <ConfigAppRoutes />
    <PrestationAppRoutes />
    <FactureAppRoutes />
    <InvitationAppRoutes />
  </div>
);
